<template>
    <div class="cp-featured-animation">
        <CpFeaturedStarIcon width="16" class="cp-featured-animation__star-big" />
        <CpFeaturedStarIcon width="10" class="cp-featured-animation__star-little" />
    </div>
</template>

<style lang="scss" scoped>
.cp-featured-animation {
  width: 24px;
  height: 22px;
  position: relative;
  display: grid;
  place-items: center;
  margin-right: 4px;

  &__star-big {
    position: absolute;
    inset: 0;
    animation: 2s ease infinite star__big;
    transform-origin: 25% 25%;
  }

  &__star-little {
    position: absolute;
    right: 0;
    bottom: 0;
    animation: 2s ease infinite star__little;
    transform-origin: 25% 25%;
  }

  @keyframes star__big {
    0% {
      transform: scale(0);
    }
    53% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes star__little {
    0% {
      transform: scale(1);
    }
    38% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
}
</style>
